import clsx from 'clsx';
import React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Logo } from '../SVGs/Logo';
import s from './Loader.module.scss';

export const Loader = ({ filled, size = 'medium' }) => {
  return (
    <div className={clsx(s.loaderContainer, filled ? s.filledLoader : '')}>
      <div className={s.loaderContent}>
        {filled && <Logo />}
        <Spinner size={SpinnerSize[size]} />
      </div>
    </div>
  );
};

export const SimpLoader = ({ size = '48px' }) => {
  return (
    <div className={s.simpLoaderCont}>
      <div className={s.loader} style={{ width: size, height: size }} />
    </div>
  );
};
