import { Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import s from '../Alert/Alert.module.scss';

export const ConfirmWComponent = ({
  type = 'success',
  title,
  children,
  onClose,
  btnText = 'Закрыть'
}) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: title
  };

  return (
    <div className={s.container}>
      <div className={s.confirm}>
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          onDismiss={onClose}
          maxWidth={'33rem'}
        >
          {children}
          <DialogFooter>
            <PrimaryButton onClick={onClose} text={btnText} />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

export default ConfirmWComponent;
