import {serializeQueryObj} from '../functions'
import API from './APIService'

class LinesAPI {
    getAllLines = () => API.get(`/api/ProductionLines/all`)
    getLineByProductId = (productId) => API.get(`/api/ProductionLine/Get?productId=${productId}`)
    getLines = (query) => API.get(`/api/Productionlines?${serializeQueryObj(query)}`)
    getLineByID = (lineID) => API.get(`/api/Productionlines/LineSettings/${lineID}`)
    updateLine = (line) => API.put(`/api/Productionlines/UpdateLineSettings`, line)
    addLine = (line) => API.post(`/api/Productionlines`, line)
    deleteLine = (lineID) => API.delete(`/api/Productionlines/${lineID}`)
}

export default new LinesAPI()
