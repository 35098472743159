import { Dropdown, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import UsersAPI from '../../api/usersAPI'
import g from '../../assets/scss/Main.module.scss'
import { Alert } from '../../components/Alert/Alert'
import { BottomButtons } from '../../components/BottomButtons/BottomButtons'
import { Confirm } from '../../components/Confirm/Confirm'
import { Loader } from '../../components/Loader/Loader'
import { TableAction } from '../../components/TableAction/TableAction'
import { deleteIcon } from '../../constants'
import { useAppContext } from '../../context/AppContext'
import { useCrumbsContext } from '../../context/CrumbsContext'
import { mapItemsForDropdown, validateField, validateForm } from '../../functions'
import { useAlert, useSendRequest } from '../../hooks'
import s from './User.module.scss'

const emptyUser = {
    userName: '',
    firstName: '',
    surname: '',
    patronymic: ''
}

export const User = () => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(emptyUser)
    const [editMode, setEditMode] = useState('edit')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordAgain, setNewPasswordAgain] = useState('')
    const [rawRoles, setRawRoles] = useState([])
    const [rolesForDropdown, setRolesForDropdown] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [userErrors, setUserErrors] = useState(new Set([]))
    const [newPasswordError, setNewPasswordError] = useState(null)
    const [isSavingConfirmShow, setIsSavingConfirmShow] = useState(false)
    const [confirmText, setConfirmText] = useState('')
    const [infoChanged, setInfoChanged] = useState(false)
    const { setIsMenuDisabled } = useAppContext()
    const { setCrumbs } = useCrumbsContext()
    let { userID } = useParams()
    const history = useHistory()
    const location = useLocation()

    const { alertTitle, alertText, isAlertShowed, hideAlert, showAlert } = useAlert()
    const { afterSending } = useSendRequest(setLoading)
    // useBackBtn('/users')

    useEffect(() => {
        setIsMenuDisabled(true)

        return () => {
            setIsMenuDisabled(false)
        }
    }, [])

    const getUser = async id => {
        setLoading(true)
        const userRes = await UsersAPI.getUserByID(id)

        if (userRes.statusCode === 400) {
            history.push('/users')
        }
        await setUser(userRes)

        await getRoles()
        await setSelectedRoles(userRes.roles.map(item => item.id))

        setLoading(false)
    }

    const getRoles = async () => {
        const roles = await UsersAPI.getRoles()
        await setRawRoles(roles)
        setRolesForDropdown(mapItemsForDropdown(roles))

        return roles
    }

    useEffect(() => {
        if (userID) {
            if (userID === 'add') {
                getRoles().then(res => {
                    setSelectedRoles([res[0]])
                    setUser({ ...user, isEmptyUser: true, roles: res[0] })
                })

                setEditMode(null)
            } else {
                getUser(userID)
            }
        }
    }, [userID])

    const deleteUser = async () => {
        const onSuccess = () => {
            showAlert('Удаление пользователя', 'Пользователь успешно удалён')
        }

        const res = await UsersAPI.deleteUser(user.id)
        afterSending(res, onSuccess)
    }

    const changeUserInfo = (e) => {
        const { name, value } = e.target
        const prevUser = user
        let newErrors = new Set(userErrors)

        if (!validateField(name, value)) {
            newErrors.add(name)
        } else {
            newErrors.delete(name)
        }
        prevUser[name] = value
        setUser({ ...prevUser })
        setUserErrors(newErrors)
        setInfoChanged(true)
    }

    useEffect(() => {
        if (user && !user.isEmptyUser) {
            setCrumbs([location.pathname.slice(0, location.pathname.lastIndexOf('/')), `${user.firstName} ${user.surname} ${user.patronymic}`])
        } else {
            setCrumbs([location.pathname.slice(0, location.pathname.lastIndexOf('/')), `Новый пользователь`])
        }
    }, [user])

    const getSelectedRoles = (roles) => {
        const res = []

        roles.forEach(item => {
            const rawRole = rawRoles.find(r => r.id === item)

            if (rawRole) res.push(rawRole)
        })
        return res
    }

    const changeUserRole = (roleId) => {
        if (selectedRoles.includes(roleId)) {
            setSelectedRoles(selectedRoles.filter(item => item !== roleId))
        } else {
            setSelectedRoles(prev => [...prev, roleId])
        }
    }

    useEffect(() => {
        setUser(prev => ({ ...prev, roles: selectedRoles }))
        setInfoChanged(true)
    }, [selectedRoles])


    useEffect(() => {
        if (newPassword || newPasswordAgain) {
            if (newPassword === newPasswordAgain) {
                setNewPasswordError(null)
            } else {
                setNewPasswordError('Пароли не совпадают')
            }
        } else {
            setNewPasswordError(null)
        }

        if (newPassword && newPassword.length < 5) {
            setNewPasswordError('Пароль должен содержать минимум 5 символов')

        }
    }, [newPassword, newPasswordAgain])

    const changePassword = async () => {
        const onSuccess = () => {
            showAlert('Изменение пароля', 'Пароль успешно изменён')
        }

        if (newPassword > 5 && (newPasswordAgain && !newPasswordError)) {
            const res = await UsersAPI.changePassword({ id: user.id, password: newPassword })
            afterSending(res, onSuccess)
        }
    }

    useEffect(() => {
        if (user.isEmptyUser) {
            let newErrors = new Set(userErrors)

            if (newPassword === newPasswordAgain) {
                newErrors.delete('newPassword')
                newErrors.delete('newPasswordAgain')
            } else {
                newErrors.add('newPasswordAgain')
            }

            setUserErrors(newErrors)
        }
    }, [newPasswordError])

    const saveUser = async () => {
        let isCorrect

        if (!editMode) {
            isCorrect = validateForm(
                {
                    firstName: user.userName,
                    surname: user.surname,
                    patronymic: user.patronymic,
                    userName: user.userName,
                    newPassword: newPassword,
                    newPasswordAgain: newPasswordAgain
                }, userErrors, setUserErrors)
        } else {
            isCorrect = validateForm(
                {
                    firstName: user.userName,
                    surname: user.surname,
                    patronymic: user.patronymic,
                    userName: user.userName
                }, userErrors, setUserErrors)
        }

        if (isCorrect) {
            const mappedRoles = getSelectedRoles(user.roles);
            if (editMode) {
                const onSuccess = () => {
                    showAlert('Редактирование пользователя', 'Пользователь успешно отредактирован')
                }

                const savedRes = await UsersAPI.editUser({ ...user, roles: mappedRoles })
                afterSending(savedRes, onSuccess)
            } else {
                const onSuccess = () => {
                    showAlert('Добавление пользователя', 'Пользователь успешно добавлен')
                }

                const newUser = { ...user, roles: mappedRoles, password: newPassword }
                delete newUser.isEmptyUser
                const addedRes = await UsersAPI.addUser({ ...newUser, roles: mappedRoles })
                afterSending(addedRes, onSuccess)
            }
        }
    }

    const clearPasswords = () => {
        setNewPassword('')
        setNewPasswordAgain('')
        hideAlert()
    }

    return (
        <>
            <div className={s.tables}>
                {isAlertShowed && <Alert title={alertTitle} text={alertText}
                    onClose={() => {
                        if (!user.isEmptyUser && newPassword) {
                            clearPasswords()
                        } else {
                            history.push('/users')
                        }
                    }} />}
                {loading && <Loader />}
                {user && <div className={g.titleWrapper}>
                    <h1 className={g.title}>{`${user.isEmptyUser ? 'Новый пользователь' : `${user.firstName} ${user.surname} ${user.patronymic}`}`}</h1>
                    {editMode &&
                        <TableAction iconName={deleteIcon} text='Удалить пользователя' isButton={true} type='danger'
                            onClick={() => {
                                setEditMode('delete')
                                setConfirmText('Вы уверены, что хотите удалить пользователя?')
                                setIsSavingConfirmShow(true)
                            }} />}
                </div>}
                <div className={s.lineTable}>
                    <div className={`${g.header} ${s.header}`}>
                        <span className={`${g.headerTitle} ${s.headerTitle}`}>Информация о пользователе</span>
                    </div>
                    <div className={s.userInfo}>
                        <form noValidate>
                            <Stack tokens={{ childrenGap: 15 }}>
                                <TextField label="Имя" name='firstName' required value={user.firstName}
                                    onChange={changeUserInfo}
                                    errorMessage={userErrors.has('firstName') && 'Заполните имя'} />
                                <TextField label="Фамилия" name='surname' required value={user.surname}
                                    onChange={changeUserInfo}
                                    errorMessage={userErrors.has('surname') && 'Заполните фамилию'} />
                                <TextField label="Отчество" name='patronymic' required value={user.patronymic}
                                    onChange={changeUserInfo}
                                    errorMessage={userErrors.has('patronymic') && 'Заполните отчество'} />
                                <TextField label="Логин" name='userName' required value={user.userName}
                                    onChange={changeUserInfo}
                                    autoComplete='off'
                                    errorMessage={userErrors.has('userName') && 'Заполните логин (только латиница и цифры, минимум 3 символа)'} />
                                {!!rolesForDropdown.length &&
                                    <Dropdown
                                        label="Роль"
                                        selectedKeys={selectedRoles}
                                        onChange={(e, item) => changeUserRole(item.key)}
                                        multiSelect
                                        options={rolesForDropdown}
                                    />}
                            </Stack>
                        </form>
                        <div className={s.changePassword}>
                            <span
                                className={s.changePasswordTitle}>{user.isEmptyUser ? 'Пароль' : 'Смена пароля'}</span>
                            <div className={s.passwords}>
                                <form noValidate>
                                    <Stack tokens={{ childrenGap: 15 }}>
                                        <TextField label={user.isEmptyUser ? 'Пароль' : 'Новый пароль'}
                                            autoComplete='off'
                                            name='newPassword'
                                            type='password' value={newPassword}
                                            errorMessage={userErrors.has('newPassword') && 'Пароль должен содержать минимум 5 символов'}
                                            onChange={e => {
                                                changeUserInfo(e)
                                                setNewPassword(e.target.value)
                                            }} />
                                        <TextField
                                            label={user.isEmptyUser ? 'Повторите пароль' : 'Повторите новый пароль'}
                                            autoComplete='off'
                                            type='password' value={newPasswordAgain}
                                            onChange={e => {
                                                setNewPasswordAgain(e.target.value)
                                            }}
                                            errorMessage={newPasswordError} />
                                    </Stack>
                                </form>
                            </div>
                            {!user.isEmptyUser && <PrimaryButton className={s.changePasswordBtn}
                                text='Сменить пароль'
                                disabled={(!newPassword && !newPasswordAgain) || newPasswordError}
                                onClick={changePassword} />}
                        </div>
                    </div>
                </div>
                {isSavingConfirmShow && <Confirm onSend={() => {
                    if (editMode === 'edit' || editMode === null) {
                        history.push('/users')
                    }
                    if (editMode === 'delete') {
                        deleteUser()
                    }
                }}
                    title='Подтверждение действия'
                    subText={confirmText}
                    okText={editMode === 'edit' ? 'Выйти' : 'Удалить'}
                    setIsShow={setIsSavingConfirmShow} />}
                <BottomButtons okText={userID === 'add' ? 'Добавить' : 'Сохранить'}
                    closeText='Назад'
                    disabledButtons={[userErrors.size > 0, false]}
                    closeHandler={() => {
                        if (infoChanged) {
                            setConfirmText('Вы уверены, что хотите выйти без сохранения?')
                            setIsSavingConfirmShow(true)
                        } else {
                            history.push('/users')
                        }
                    }}
                    okHandler={saveUser} />
            </div>
        </>
    )
}
