
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import s from './ConsignmentExtraInfo.module.scss';


export const ExtraConsignmentFields = ({ consignmentInfo }) => {

  const extraFields = {
    'Отсканировано КМ': consignmentInfo.productScanCount,
    'Валидных КМ': consignmentInfo.productValidScanCount,
    'Несчитанных КМ': consignmentInfo.productNoReadScanCount,
    'КМ, не найденных в БД': consignmentInfo.productScanNoFoundCount,
    'Дубликатов КМ': consignmentInfo.productDuplicateScanCount,
    'КМ, невалидных по GTIN': consignmentInfo.productScanInvalidGtinCount,
    'КМ, невалидных по длине кода': consignmentInfo.productScanInvalidLengthCount,
    'Отбракованных КМ': consignmentInfo.productDropoutCount,
    'Всего коробов': consignmentInfo.agregation1ScanCount,
    'Валидных коробов': consignmentInfo.agregation1ScanValid,
    'Несчитанных коробов': consignmentInfo.agregation1ScanNoRead,
    'Невалидных коробов': consignmentInfo.agregation1ScanInvalid,
    'Всего паллет': consignmentInfo.agregation2ScanCount,
    'Валидных паллет': consignmentInfo.agregation2ScanValid,
    'Несчитанных паллет': consignmentInfo.agregation2ScanInvalid
  }

  const extraFieldsLimited = {
    'Отсканировано КМ': consignmentInfo.productScanCount,
    'Валидных КМ': consignmentInfo.productValidScanCount,
    'Несчитанных КМ': consignmentInfo.productNoReadScanCount,
    'КМ, не найденных в БД': consignmentInfo.productScanNoFoundCount,
    'Дубликатов КМ': consignmentInfo.productDuplicateScanCount,
    'КМ, невалидных по GTIN': consignmentInfo.productScanInvalidGtinCount,
    'КМ, невалидных по длине кода': consignmentInfo.productScanInvalidLengthCount
  }

  const isAggregationIncluded = useSelector(state => state.app.aggregationIncluded);

  const [extraInfo, setInfo] = useState(extraFields);

  useEffect(() => {
    if (!isAggregationIncluded) setInfo(extraFieldsLimited)
  }, [isAggregationIncluded])

  return (
    <div className={s.extraConsignmentInfo}>
      {Object.keys(extraInfo).map((fieldName, i) => {
        return (
          <div className={s.infoRow} key={i}>
            <span>{fieldName} :</span>
            <span className={s.infocell}>{extraFields[fieldName]}</span>
          </div>
        )
      })}
    </div>
  )
}
