import { serializeQueryObj } from '../functions';
import API from './APIService';

class ConsignmentsAPI {
  getAllConsignments = (lineId) => API.get(`/api/Consignment/all/${lineId}`);
  getConsignments = (query) => API.get(`/api/Consignment/all?${serializeQueryObj(query)}`);
  getConsignmentById = (consignmentId) => API.get(`/api/Consignment/${consignmentId}`);
  editConsignment = (consignment) => API.put(`/api/Consignment`, consignment);
  createConsignment = (consignment) => API.post(`/api/Consignment`, consignment);
  // TODO убрать dev 1
  getPrinters = () => API.get('/api/Printer/AvailablePrinters?dev=1');
  preliminaryPrint = (payload) => API.put('/api/Printer/PrintConsignmentMarkingCodes', payload);
  preliminaryProductPrint = (payload) => API.put('/api/Printer/PrintCodes', payload);
}

export default new ConsignmentsAPI();
