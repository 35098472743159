import React from 'react';
import s from './Confirm.module.scss';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton
} from 'office-ui-fabric-react';

export const Confirm = ({ setIsShow, onSend, onDecline, title, subText, okText, declineText }) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    subText: subText
  };

  return (
    <div className={s.container}>
      <div className={s.confirm}>
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          onDismiss={() => setIsShow(false)}
        >
          <DialogFooter className={s.buttonsContainer}>
            <PrimaryButton onClick={onSend} text={okText} />
            <DefaultButton onClick={() => setIsShow(false)} text={declineText || 'Отмена'} />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};
