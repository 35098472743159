import s from './Consignment.module.scss';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Confirm } from 'components/Confirm/Confirm';
import { useState } from 'react';

const DeleteTestConsigment = ({ showButton }) => {
  const [isModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const handleSend = () => console.log('sending is going');

  return (
    <main className={s.deleteConsSection}>
      {showButton ? (
        <section>
          <PrimaryButton
            className={s.deleteButton}
            disabled={false}
            text="Удалить задание"
            onClick={openModal}
            // style={{ marginTop: '2rem', width: '100%' }}
          />
        </section>
      ) : null}
      {isModal ? (
        <Confirm
          title={'Задание будет удалено. Продолжить?'}
          okText={'ОК'}
          setIsShow={setShowModal}
          onSend={handleSend}
        />
      ) : null}
    </main>
  );
};

export default DeleteTestConsigment;
